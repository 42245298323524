.coloredKey {
    width: 32px;
    height: 32px;
}

.scheduleLegendContainer {
    padding-top: 64px;
}

.legend-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.legend-item-text {
    text-align: left;
}
