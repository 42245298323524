.original-song-card {
  padding: 16px;
  text-align: center;
  width: 100%;
  min-width: 100%;

  .song-title {
    color: #e79e31;
    text-transform: uppercase;
  }

  .band-name {
    color: #e79e31;
  }
}
