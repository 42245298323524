.home-container {
  background: rgb(232, 231, 228);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 100vh;

  .competition-selection-drop-down {
    padding: 8px 0;
  }

  .home-router {
    padding: 16px;
  }

  .public-route-buttons {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .admin-route-buttons {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .home-route-button {
    padding: 16px;
  }
}