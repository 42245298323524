.judges-comments-form {
  padding: 16px 0;

  .text-area-question-group {
    padding: 8px 16px 0;
    margin-bottom: unset;
    text-align: left;

    .text-area {
      height: 400px;
    }
  }
}