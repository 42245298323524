.weekly-results-container {
  max-width: 100%;

  .weekly-ranking-bar-chart-container {
    margin: 16px 0;

    .weekly-ranking-bar-chart {
    }
  }

  .weekly-judges-comments-container {
    padding: 16px;

    .all-judges-have-submitted-alert {
      text-align: center;
    }

    .weekly-judges-feedback-container {
      border: 1px solid black;
      overflow-y: scroll;
      max-height: 800px;
    }
  }
}