.original-song-competition-container {
  background: rgb(232, 231, 228);
  min-height: 100vh;

  .button-container {
    padding-left: 16px;
    text-align: left;

    .submit-button {
      background: rgb(123, 93, 151);
    }
  }

  .judging-reminder-alert-container {
    padding: 16px;

    .judging-reminder-alert {
      text-align: left;
      color: #e79e31;
      font-weight: bold;
    }
  }

  .original-song-container {
    text-align: center;
    padding: 16px;

    .overall-band-rankings-row {
      padding: 0 16px 16px 16px;

      .overall-band-rankings-card {
        padding: 16px;

        .ranking-instructions {
          text-align: left;
          padding: 16px;

          .warning {
            color: #e79e31;
            font-weight: bold;
          }
        }

        .ranking-dropdown-container {
          text-align: left;
          padding: 16px;

          .ranking-title {
            font-weight: bold;
          }

          .ranking-dropdown {
          }
        }
      }
    }

    .original-song-info-row {
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .original-song-info-card {
        padding: 16px;
        height: 100%;

        .band-selection-dropdown {
          text-align: left;
        }

        .original-song-info-container {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          align-content: center;
          justify-content: center;

          .original-song-selection-button-container {
            padding: 0 16px;
          }

          .original-song-info-content {
            padding: 16px;

            .video {
              padding: 8px;
              text-align: center;
            }

            .subtitle {
              font-weight: bold;
            }

            .song-description {
              text-align: left;
            }
          }
        }
      }

      .original-song-judging-form-card {
        height: 100%;
        padding: 16px;
      }
    }

    .artist-statement-card-row {
      .lyrics-card-container {
        padding: 16px;
        width: 100%;

        .dropdown-chevron {
          display: inline-block;
          position: absolute;
          left: 0;
          padding: 16px;
        }

        .title-container {
          display: inline-block;
          padding-left: 16px;
        }
      }
    }
  }
}