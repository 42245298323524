#root {
  @import 'static/scss/OriginalSongCompetition';
  @import 'static/scss/TextAreaFormInput';
  @import 'static/scss/OriginalSongCompetitionSchedule';
  @import 'static/scss/WeeklyResults';
  @import 'static/scss/Home';
  @import 'static/scss/CreateCompetition';
  @import 'static/scss/IncompleteApplications';
}

@import 'static/scss/Default';
@import "~video-react/styles/scss/video-react";
@import 'static/scss/Sidebar';

.selected {
  background: #e28732 !important;
  color: white !important;
}

.main-content {
  padding: 0;
  width: 80vw;
  height: 100vh;
  overflow-y: auto;
}

.btn-secondary {
  background: rgb(123, 93, 151);
}

.sidebar-and-main-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
}