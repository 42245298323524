.create-competition-container {
  .step-label-content {
    display: flex;
    flex-direction: column;
  }

  .competition-type-drop-down-container {
    text-align: center;
    padding-bottom: 16px;
  }

  .step-navigation-buttons {
    display: flex;
    justify-content: center;
  }

  .navigation-button-container {
    padding: 0 4px;
  }

  .step-container {
    padding: 16px;
  }
}